document.addEventListener("turbolinks:load", function() {
  ///// Form filter
  $('#main_section_selection').on('ajax:success', '#klass_section_klass_id', function(event) {
  // $('#main_section_selection').change(function() {
    var detail = event.detail;
    var data = detail[0], status = detail[1], xhr = detail[2];
    console.log(event);

    // get second selectbox by its id
    var container_select = $('#klass_section_subclass_id');
    // empty it
    container_select.empty();
    // Start with a blank option
    var blank_option = $('<option/>');
    blank_option.appendTo(container_select);
    // we got a JSON array in data, iterate through it
    $.each(data, function(index, value) {
      // append an option
      var opt = $('<option/>');
      // value is an array: [:id, :name]
      opt.attr('value', value[0]);
      // set text
      opt.text(value[1]);
      // append to select
      opt.appendTo(container_select);
    });
    console.log(data);
  });
});

document.addEventListener("turbolinks:load", function() {
  $(".translatorBox").on("keyup input", function(e) {
    // user has pressed backspace
    if(e.keyCode == 8 || e.keyCode == 32) {
      var translationParams = $(this).val();
      var startingLanguage = $(this).data("start"); // What is being typed
      var translatePath = $(".translatePath").data("url"); // Where to send data
      var replacingText = $("#languageToCommon"); // Default replacing box

      if (startingLanguage != "common") {
        replacingText = $("#commonToLanguage"); // Switch replacing box if needed
      }

      var form = $(".translateForm"); // grab the form wrapping the search bar.
      var url = translatePath; // live_search action.
      var formData = form.serialize(); // grab the data in the form
      $.get(url, {start: startingLanguage, phrase: translationParams}, function(translated) { // perform an AJAX get
        // console.log(translated);
        translated = translated.replaceAll("&#39;", "'"); // replace ascii with '
        replacingText.val(translated); // replace the "results" div with the results
      });
   }

    // Debug
    // console.log(replacingText);
  });
});

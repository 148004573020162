document.addEventListener("turbolinks:load", function () {
  ////////////////////////// Close button for notice
  $('#closeActionNotice').click(function() {
    $('#actionNotice').addClass('d-none');
  });

  ////////////////////////// Layout menu toggle
  $("#menu-toggle").click(function(e) {
    e.preventDefault();
    $("#app-wrapper").toggleClass("toggled");
  });

  ////////////////////////// Scrollbar Position
  var checkSpyScrollbar = $('#sidebar-scroller .nav-item.active');
  if (checkSpyScrollbar.length) {
    var current_scroll_pos = checkSpyScrollbar.position().top;
    $("#sidebar-scroller").scrollTop(current_scroll_pos / 2);
  }

  ////////////////////////// TABS
  var tabsList = [].slice.call(document.querySelectorAll('[role="tablist"]'));
  tabsList.map(function (tabTriggerEl) {
    return new bootstrap.Tab(tabTriggerEl);
  });
  // var triggerFirstTabEl = document.querySelector('#myTab li:first-child a')
  // bootstrap.Tab.getInstance(triggerFirstTabEl).show() // Select first tab
  // $('.nav-tabs a').on('click', function(e) {
  //   e.preventDefault();
  //   $(this).tab('show');
  // });

  ////////////////////////// Tooltips
  var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
  tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new bootstrap.Tooltip(tooltipTriggerEl);
  });

  ////////////////////////// SCROLLER
  const scrollSpy = new bootstrap.ScrollSpy(document.body, {
    target: '#scrollTrackingList'
  });

  ////////////////////////// Popovers
  // $(function () { $('[data-bs-toggle="popover"]').popover(); });
  const popoverTriggerList = document.querySelectorAll('[data-bs-toggle="popover"]');
  const popoversRun = [...popoverTriggerList].map(popoverTriggerEl => {
    return new bootstrap.Popover(popoverTriggerEl);
  });
  popoversRun;
  // Hide all popovers on any outside click
  $('body').on('click', function (e) {
    $('[data-bs-toggle=popover]').each(function () {
      // hide any open popovers when the anywhere else in the body is clicked
      if (!$(this).is(e.target) && $(this).has(e.target).length === 0 && $('.popover').has(e.target).length === 0) {
        $(this).popover('hide');
      }
    });
  });
});

document.addEventListener("turbolinks:load", function() {
  // Sortable with Tables
  $("#loreResearchesList, .sortableTable").sortable({
    placeholder: "tr bg-light",
    cancel: "td:not(.grip-box)",
    update: function(e, ui) {
      // Ajax call to save sort order
      $.ajax({
        url: $(this).data("url"),
        type: "PATCH",
        data: $(this).sortable('serialize'),
        headers: {
          'X-Transaction': 'POST Example',
          'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
        }
      });
      console.log($(this).sortable('serialize'));
    }
  });
});

import Quill from "quill"
// import Mention from "quill-mention"
import { ImageUpload } from 'quill-image-upload';
import ImageResize from "@taoqf/quill-image-resize-module/image-resize.min";

// Quill.register("modules/mention", Mention);
Quill.register('modules/imageUpload', ImageUpload);
Quill.register('modules/imageResize', ImageResize);

// const atValues = [
//   { id: 1, value: "Fredrik Sundqvist" },
//   { id: 2, value: "Patrik Sjölin" }
// ];
// const hashValues = [
//   { id: 3, value: "Fredrik Sundqvist 2" },
//   { id: 4, value: "Patrik Sjölin 2" }
// ];

function quill_editor_loader() {
  var toolbarOptions = [
    [{ 'size': ['small', false, 'large', 'huge'] }],
    [{ header: [1, 2, 3, 4, 5, false] }],
    ['bold', 'italic', 'underline', 'strike'],
    [{ 'align': [] }],
    [{ 'indent': '-1'}, { 'indent': '+1' }],
    ['blockquote', 'code-block'],
    ['link', 'image'],
    [{ 'color': [] }, { 'background': [] }],
    [{ 'list': 'ordered'}, { 'list': 'bullet' }, { 'list': 'check' }],
    ['clean']
  ];

  var imageUploadOptions = {
    url: '', // server url. If the url is empty then the base64 returns
    method: 'POST', // change query method, default 'POST'
    name: 'image', // custom form name
    withCredentials: false, // withCredentials
    // headers: {}, // add custom headers, example { token: 'your-token'}
    // csrf: { token: 'token', hash: '' }, // add custom CSRF
    // customUploader: () => {}, // add custom uploader
    // personalize successful callback and call next function to insert new url to the editor
    callbackOK: (serverResponse, next) => {
      next(serverResponse);
    },
    // personalize failed callback
    callbackKO: serverError => {
      alert(serverError);
    },
    // optional
    // add callback when a image have been chosen
    checkBeforeSend: (file, next) => {
      // Log file information to console for testing
      console.log(file);

      // If file size is greater than 500kb, block upload
      if(file.size > 500000) {
        console.log("Image file size is too large to add.");
        alert('Image file size is too large to add. We are sorry, files within the text editor have a size maximum of 500px on either side and 500KB.');
        return false;
      } else {
        // Checking image dimensions
        var imgwidth = 0;
        var _URL = window.URL || window.webkitURL;
        // img = file
        var img = file;
        img = new Image();
        img.onload = function () {
          //alert(this.width + " " + this.height);
          if(this.width > 500) {
            alert('Image is too wide. We are sorry, files within the text editor have a size maximum of 500px on either side and 500KB.');
            return false;
          } else if(this.height > 500) {
            alert('Image is too wide. We are sorry, files within the text editor have a size maximum of 500px on either side and 500KB.');
            return false;
          } else {
            // Send and post image
            console.log("Image added successfully.");
            next(file); // go back to component and send to the server
          }
        };
        img.src = _URL.createObjectURL(file);
      }
    }
  }

  // Mention Values
  // const atValues = [
  //   { id: 1, value: "Fredrik Sundqvist" },
  //   { id: 2, value: "Patrik Sjölin" }
  // ];
  // const hashValues = [
  //   { id: 3, value: "Fredrik Sundqvist 2" },
  //   { id: 4, value: "Patrik Sjölin 2" }
  // ];
  //
  // var mentionOptions = {
  //   allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
  //   mentionDenotationChars: ["@", "#"],
  //   source: function(searchTerm, renderList, mentionChar) {
  //     let values;
  //
  //     if (mentionChar === "@") {
  //       values = atValues;
  //     } else {
  //       values = hashValues;
  //     }
  //
  //     if (searchTerm.length === 0) {
  //       renderList(values, searchTerm);
  //     } else {
  //       const matches = [];
  //       for (let i = 0; i < values.length; i++)
  //         if (
  //           ~values[i].value.toLowerCase().indexOf(searchTerm.toLowerCase())
  //         )
  //           matches.push(values[i]);
  //       renderList(matches, searchTerm);
  //     }
  //   }
  // }

  var quillOptions = {
    modules: {
      toolbar: toolbarOptions,
      imageUpload: imageUploadOptions,
      table: true,
      // mention: mentionOptions,
      imageResize: {
        displaySize: true,
        displayStyles: {
          backgroundColor: 'black',
          border: 'none',
          color: 'white'
        },
        modules: [ 'Resize', 'DisplaySize', 'Toolbar' ]
      }
    },
    theme: 'snow'
  };

  // Quill Settings
  var quillies = ["", "_two", "_three", "_four", "_five", "_six", "_seven", "_eight"]

  quillies.forEach(function(e) {
    if(document.getElementById("quill_editor"+e) !== null) {
      // Dynamically create quill variables
      //window["quill"+e] = new Object();
      window["quill"+e] = new Quill('#quill_editor'+e, quillOptions);

      // Tables in editor
      window["table"+e] = window["quill"+e].getModule('table');
      if(document.querySelector('.quill_container_table_controls') !== null) {
        document.querySelector('#quill'+e+'-insert-table').addEventListener('click', function() {
          window["table"+e].insertTable(2, 2);
        });
        document.querySelector('#quill'+e+'-insert-row-above').addEventListener('click', function() {
          window["table"+e].insertRowAbove();
        });
        document.querySelector('#quill'+e+'-insert-row-below').addEventListener('click', function() {
          window["table"+e].insertRowBelow();
        });
        document.querySelector('#quill'+e+'-insert-column-left').addEventListener('click', function() {
          window["table"+e].insertColumnLeft();
        });
        document.querySelector('#quill'+e+'-insert-column-right').addEventListener('click', function() {
          window["table"+e].insertColumnRight();
        });
        document.querySelector('#quill'+e+'-delete-row').addEventListener('click', function() {
          window["table"+e].deleteRow();
        });
        document.querySelector('#quill'+e+'-delete-column').addEventListener('click', function() {
          window["table"+e].deleteColumn();
        });
        document.querySelector('#quill'+e+'-delete-table').addEventListener('click', function() {
          window["table"+e].deleteTable();
        });

        // Actiontext data attributes
        $(".ql-editor").attr("data-controller", "mentions");
        $(".ql-editor").attr("data-target", "mentions.field");
      }
    }
  });

  // JavaScript to fill in post content on from submission
  var form = document.querySelector('.quill_form');
  if(form !== null) {
    form.onsubmit = function() {
      var postContentInput_one = document.querySelector('#editorContentOne');
      if(postContentInput_one !== null) { postContentInput_one.value = document.querySelector("#quill_editor .ql-editor").innerHTML; }

      var postContentInput_two = document.querySelector('#editorContentTwo');
      if(postContentInput_two !== null) { postContentInput_two.value = document.querySelector("#quill_editor_two .ql-editor").innerHTML; }

      var postContentInput_three = document.querySelector('#editorContentThree');
      if(postContentInput_three !== null) { postContentInput_three.value = document.querySelector("#quill_editor_three .ql-editor").innerHTML; }

      var postContentInput_four = document.querySelector('#editorContentFour');
      if(postContentInput_four !== null) { postContentInput_four.value = document.querySelector("#quill_editor_four .ql-editor").innerHTML; }

      var postContentInput_five = document.querySelector('#editorContentFive');
      if(postContentInput_five !== null) { postContentInput_five.value = document.querySelector("#quill_editor_five .ql-editor").innerHTML; }

      var postContentInput_six = document.querySelector('#editorContentSix');
      if(postContentInput_six !== null) { postContentInput_six.value = document.querySelector("#quill_editor_six .ql-editor").innerHTML; }

      var postContentInput_seven = document.querySelector('#editorContentSeven');
      if(postContentInput_seven !== null) { postContentInput_seven.value = document.querySelector("#quill_editor_seven .ql-editor").innerHTML; }

      var postContentInput_eight = document.querySelector('#editorContentEight');
      if(postContentInput_eight !== null) { postContentInput_eight.value = document.querySelector("#quill_editor_eight .ql-editor").innerHTML; }
    };
  }
}

// document.addEventListener("DOMContentLoaded", function (event) {
document.addEventListener("turbolinks:load", function() {
  quill_editor_loader();

  document.querySelectorAll('.quillModal').forEach(item => {
    item.addEventListener('focus', event => {
      quill_editor_loader();
    })
  });

  // Skip Quill toolbar on tabbing
  $(".ql-toolbar").find(":button").attr('tabindex', '-1');
  // $(".ql-toolbar").addClass("sticky-top")

  $(".ql-editor").on('focus', function(){
     $(this).closest(".form-group").find(".ql-toolbar").toggleClass('sticky-top');
  });
});
